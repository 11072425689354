import React, { Fragment } from 'react'

import RichTextEditor from 'react-rte'
import { Button, Collapse, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class TouristicData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })
  toolbarConfig = () => ({
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Fett', style: 'BOLD' },
      { label: 'Kursiv', style: 'ITALIC' },
      { label: 'Durchgestrichen', style: 'STRIKETHROUGH' },
      { label: 'Unterstrichen', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Text', style: 'unstyled' },
      { label: 'Überschrift H1', style: 'header-one' },
      { label: 'Überschrift H2', style: 'header-two' },
      { label: 'Überschrift H3', style: 'header-three' },
      { label: 'Überschrift H4', style: 'header-four' },
      { label: 'Überschrift H5', style: 'header-five' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'Liste (unsortiert)', style: 'unordered-list-item' },
      { label: 'Liste (sortiert)', style: 'ordered-list-item' },
      { label: 'Blockzitat', style: 'blockquote' }
    ]
  })
  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      toolbarConfig,
      state: { status, isOpen, tooltip_open },
      props: { facility, locale, editContentRight }
    } = this

    const touristic_keys = [
      'touristic_short_description_de',
      'touristic_short_description_en',
      'touristic_description_de',
      'touristic_description_en',
      'touristic_note_de',
      'touristic_note_en',
      'service_info_de',
      'service_info_en',
      'booking_info_de',
      'booking_info_en'
    ]

    return (
      <React.Fragment>
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">Touristische Informationen</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {facility?.permissions?.update_base_data ? (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`touristic`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip delay={500} placement="top" isOpen={tooltip_open} target={`touristic`} toggle={toggleTooltip}>
                  {locale.edit}
                </Tooltip>
              </div>
            ) : null}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            {touristic_keys.map((touristic_key, i) => (
              <Fragment key={i}>
                <h5>{locale[touristic_key]}</h5>
                <RichTextEditor
                  disabled
                  value={RichTextEditor.createValueFromString(facility?.base_data[touristic_key] || '', 'html')}
                />
                <br />
              </Fragment>
            ))}
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default TouristicData
