import React, { Fragment } from 'react'

import { Badge, Button, Collapse, Tooltip, UncontrolledTooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class PartnerGateway extends React.Component {
  state = {
    isOpen: false,
    status: 'closed'
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open },
      props: { facility, locale, editContentRight }
    } = this

    return (
      <React.Fragment>
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">Partner Gateway </span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
              {this.props.facility.partner_gateway_settings.listed && (
                <Fragment>
                  <Badge id="public_version" pill color="secondary">
                    v{this.props.facility.partner_gateway_settings.public_version_number}
                  </Badge>
                  <UncontrolledTooltip delay={500} target="public_version">
                    Veröffentlicht
                  </UncontrolledTooltip>
                </Fragment>
              )}
            </h3>
            {facility?.permissions?.update_partner_gateway_settings && (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`partner_gateway`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`partner_gateway`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </div>
            )}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            {this.props.facility.partner_gateway_settings.listed ? (
              <p>
                Dieser Betrieb wird in der{' '}
                <a
                  href={`/facility/${this.props.facility.uuid}/${this.props.facility.partner_gateway_settings.public_version_number}`}
                >
                  Version {this.props.facility.partner_gateway_settings.public_version_number}
                </a>{' '}
                über das Partner Gateway veröffentlicht.{' '}
              </p>
            ) : (
              <p>
                Dieser Betrieb wird <u>nicht</u> über das Partner Gateway veröffentlicht
              </p>
            )}
            <p>
              {this.props.facility.partner_gateway_settings.publication_allowed
                ? 'Der Betrieb hat sein Einverständnis zur Veröffentlichung gegeben.'
                : 'Der Betrieb hat noch kein Einverständnis zur Veröffentlichung gegeben.'}
            </p>
            <p>
              <strong>Englisch:</strong>{' '}
              {this.props.facility.partner_gateway_settings.en_available ? 'verfügbar' : 'nicht verfügbar'}
            </p>
            <p>
              <strong>Bemerkungen:</strong> {this.props.facility.partner_gateway_settings.publication_comments}
            </p>
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default PartnerGateway
