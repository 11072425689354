import React from 'react'

import { Col, Collapse, Row, Table } from 'reactstrap'

import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import moment from 'moment'

class Downloads extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })

  downloadTime = download => {
    return `${moment(download.created_at).format('L')} / ${moment(download.created_at).fromNow()}`
  }

  render() {
    const {
      toggle,
      onEntering,
      onExiting,
      props: { downloads, locale },
      state: { status, collapse },
      downloadTime
    } = this

    return (
      <Row>
        <Col sm="12" lg={{ size: 6, offset: 6 }}>
          <div className="collapse-container">
            <div className={`collapse-header btn d-flex align-items-center flex-row clearfix ${status}`}>
              <h2 className="h4 collapse-headline" onClick={toggle}>
                <span className="collapse-headline-text">Offline Downloads</span>
                <span className="collapse-icon icon-append">
                  <FontAwesomeIcon size="2x" icon={faAngleDown} />
                </span>
              </h2>
            </div>
            <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
              <p>{locale.downloads.hint}</p>
              <Table>
                <thead>
                  <tr>
                    <th>{locale.name}</th>
                    <th>{locale.email}</th>
                    <th>{locale.downloads.device}</th>
                    <th>{locale.downloads.time}</th>
                  </tr>
                </thead>
                <tbody>
                  {downloads.map(download => (
                    <tr key={download.id}>
                      <td>{download.user.full_name}</td>
                      <td>{download.user.email}</td>
                      <td>{download.device || locale.downloads.unknown_device}</td>
                      <td>
                        {moment().diff(download.created_at, 'days') >= 14 ? (
                          <strong className="text-danger">{downloadTime(download)}</strong>
                        ) : (
                          downloadTime(download)
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Collapse>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Downloads
