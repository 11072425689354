import { useState } from 'react'

import { Alert, Collapse } from 'reactstrap'

import { faAngleDown, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { withLocale } from 'locale'

import '../../../../modules/download/_download.scss'

const AssetDownloads = ({ locale, facility: { asset_download } }) => {
  const [open, setOpen] = useState(false)
  status = open ? 'opened' : 'closed'

  return (
    <div className="collapse-container">
      <div className={`collapse-header btn clearfix ${status}`} onClick={_ => setOpen(!open)}>
        <h3 className="collapse-headline">
          <span className="collapse-headline-text">{locale.downloadTitle}</span>
          <span className="collapse-icon icon-append">
            <FontAwesomeIcon size="2x" icon={faAngleDown} />
          </span>
        </h3>
      </div>
      <Collapse
        className="collapse-content"
        isOpen={open}
        onEntering={_ => setOpen(true)}
        onExiting={_ => setOpen(false)}
      >
        {!!asset_download ? (
          <a href={asset_download.url} target="_blank">
            {locale.urlLink} <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        ) : (
          <Alert color="warning" className="m-0">
            {locale.downloadMissing}
          </Alert>
        )}
      </Collapse>
    </div>
  )
}

export default withLocale(AssetDownloads, { key: 'asset_download' })
