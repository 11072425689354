// additional components
import React from 'react'

import { Button, Collapse, Table, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class FacilityKinds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      props: { facility, locale, editContentRight },
      state: { status, isOpen, tooltip_open }
    } = this

    const { kinds } = facility.base_data

    return (
      <React.Fragment>
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">{locale.facility_kind.plural}</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {facility.permissions.update_base_data && (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`facility_kinds`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`facility_kinds`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </div>
            )}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            <Table striped responsive bordered>
              <tbody>
                {kinds.map((kind, i) => (
                  <tr key={i}>
                    <th className="align-top">{kind.name_de}</th>
                    <td>
                      <ul>
                        {kind.sub_kinds.map((sub_kind, j) => (
                          <li key={j}>{sub_kind.name_de}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default FacilityKinds
