import React, { Fragment } from 'react'

import { animateScroll } from 'react-scroll'
import { Alert, Badge, Button, Col, Row } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFacility } from './facility.graphql'

import db from '../../../indexed-db/db.js'
import { DD_MM_YYYY, DD_MM_YYYY__HH_mm } from '../../helper/helper-functions.jsx'
import Loading from '../../helper/loading/loading.jsx'
import CopyFacilityModal from '../../modules/modal/copyFacilityModal'
import DeleteFacilityModal from '../../modules/modal/deleteFacilityModal'
import OfflineFacilityModal from '../../modules/modal/offlineFacilityModal'
import FacilityNameWithBundleInfo from '../facilities/helper/facility_name_with_bundle_info'
import AuditOrderNumber from './audit-order-number/audit-order-number'
import CertificationStatus from './certification-workflow/facility-certification-status.jsx'
import CertificationWorkflow from './certification-workflow/facility-certification-workflow.jsx'
import ContactPersonCertification from './contact-person-certification/contact-person-certification'
import Downloads from './downloads/downloads'
import Images from './images/images.jsx'
import { Information } from './information/information.jsx'
import InformationUpdate from './information/update.jsx'
import LicenseOwner from './license-owner/license-owner'
import { Provider } from './provider'
import Summary from './reportings/summary'
import Sections from './sections/sections'
import SectionDatumUpdate from './sections/update.jsx'
import ShortReports from './short-reports/short_reports'
import Training from './training/training.jsx'
import VersionInfo from './version_info/version_info'

import '../../modules/collapse/_collapse.scss'
import './_facility.scss'

class Facility extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      offline_available: null
    }
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (this.state.offline_available == null) {
      db.keys('Facilities')
        .then(keys => {
          let offline_available = keys.includes(this.props.match.params.uuid)
          return this.props.match.url.includes('offline') ? false : offline_available
        })
        .catch(error => {
          if (error.name === 'InvalidStateError') {
            console.error('Could not access indexedDB:', error)
            return false
          } else {
            throw error
          }
        })
        .then(offline_available => {
          if (this._mounted) this.setState({ offline_available })
        })
    }

    if (this.state.offline_available === true)
      this.props.history.push(`/offline-facility/${this.props.match.params.uuid}`)
  }

  componentDidMount = () => {
    this._mounted = true
    this.componentDidUpdate()
  }

  componentWillUnmount = () => {
    this._mounted = false
  }

  loading = () => (
    <Fragment>
      <br />
      <br />
      <br />
      <br />
      <Loading />
    </Fragment>
  )

  render() {
    let {
      props,
      state: { offline_available }
    } = this

    if (offline_available === null) return this.loading()

    if (offline_available === false)
      return (
        <Query
          query={getFacility}
          fetchPolicy="network-only"
          variables={
            props.history.location.pathname.includes('offline-facility')
              ? {
                  uuid: props.match.params.uuid,
                  version_number: ''
                }
              : {
                  uuid: props.match.params.uuid,
                  version_number: props.match.params.version_number || ''
                }
          }
        >
          {({ loading, error, data }) => {
            if (error) return <div className="mt-5">{`There was an error fetching the facility: ${error}`}</div>
            if (loading || !data) return this.loading()
            return <FacilityView {...props} facility={data.facility} />
          }}
        </Query>
      )

    return null
  }
}

class FacilityView extends React.Component {
  user_can_delete_facility = () => this.props.facility.permissions.destroy
  user_can_copy_facility = () => this.props.facility.permissions.duplicate_facility

  render = () => {
    const { props } = this
    const { facility, online, match } = props
    return (
      <div className="page-wrapper">
        <Row className="page-header">
          <Col sm="12" lg="12">
            <h1>
              <FacilityNameWithBundleInfo
                name={facility.base_data.name_de}
                firstBundledFacility={
                  facility.bundleInformation.bundledFacilities.nodes.slice(0, 1).map(bundledFacility => ({
                    name: bundledFacility.base_data.name.de,
                    audit_order_number: bundledFacility.certification_data.audit_order_number
                  }))[0]
                }
                totalBundledFacilities={facility.bundleInformation.bundledFacilities.totalCount}
                firstBundledByFacility={
                  facility.bundleInformation.bundledBy.nodes.slice(0, 1).map(bundledByFacility => ({
                    name: bundledByFacility.base_data.name.de,
                    audit_order_number: bundledByFacility.certification_data.audit_order_number
                  }))[0]
                }
                totalBundledByFacilities={facility.bundleInformation.bundledBy.totalCount}
              />
            </h1>
            {!props.offlineMode && (
              <VersionInfo
                isCurrentVersion={facility.is_current_version}
                versionNumber={facility.version_number}
                publicVersionNumber={facility.partner_gateway_settings.public_version_number}
                isPublished={facility.partner_gateway_settings.listed}
                publicIsCurrentVersion={facility.partner_gateway_settings.current_and_public_equality}
                facilityUuid={facility.uuid}
              />
            )}
          </Col>
          <Col sm="12" lg="12" className="text-right facility-actions">
            {this.user_can_delete_facility() && <DeleteFacilityModal {...props} />}
            {` `}
            {this.user_can_copy_facility() && <CopyFacilityModal {...props} />}
            {!props.facility.is_current_version && (
              <Button
                outline
                color={'primary-light'}
                className={'px-5 btn-labeled'}
                onClick={() => props.history.push(`/facility/${props.facility.uuid}`)}
              >
                <FontAwesomeIcon className="icon-prepend" icon={faCodeBranch} />
                <span>Zur aktuellen Version</span>
              </Button>
            )}
            {'serviceWorker' in navigator && <OfflineFacilityModal {...props} />}
          </Col>
        </Row>
        {props.offlineMode ? (
          <Alert color="warning">
            {props.locale.offline_facility_hint({
              downloaded: facility.download.created_at
            })}
          </Alert>
        ) : null}
        {facility.downloads && facility.downloads.length > 0 && !props.offlineMode ? (
          <Downloads downloads={facility.downloads} locale={props.locale} />
        ) : null}
        <hr className="seperator" />
        <LicenseOwner {...props} />
        <CollectionDates {...props} />
        <CertificationWorkflow {...props} />

        <AuditOrderNumber {...props} />
        {props.facility?.reportings?.length > 0 && <Summary {...props} />}
        <CertificationStatus {...props} />
        <ContactPersonCertification {...props} />
        <Provider {...props} />
        <Training {...props} />
        <SectionBaseDataWrapper {...props} />
        <ShortReports {...props} />
        <Images {...props} />
      </div>
    )
  }
}

class SectionBaseDataWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: '',
      edit_section_left: false,
      create_section_left: false,
      editing_left: false,
      editing_right: false,
      edit_result_section: null
    }

    this.editBoxLeft = React.createRef()
  }

  showEditBoxLeft = () =>
    this.setState(
      {
        editing_left: true
      },
      () => animateScroll.scrollMore(this.editingWrapper.getBoundingClientRect().top, { duration: 200 })
    )

  showEditBoxRight = () =>
    this.setState(
      {
        editing_right: true
      },
      () => animateScroll.scrollMore(this.editingWrapper.getBoundingClientRect().top, { duration: 200 })
    )

  hideEditBoxLeft = () =>
    this.setState({
      edit_section_left: false,
      create_section_left: false,
      editing_left: false
    })

  hideEditBoxRight = () =>
    this.setState({
      editing_right: false
    })

  editContentLeft = event => {
    event.stopPropagation()
    this.setState(
      {
        edit_section_left: true,
        update_section_uuid: event.target.id.split('_')[1]
      },
      this.showEditBoxLeft()
    )
  }

  editContentRight = event => {
    event.stopPropagation()
    this.setState({ type: event.target.id }, this.showEditBoxRight())
  }

  render = () => {
    return (
      <div className="edditing-wrapper" ref={el => (this.editingWrapper = el)}>
        <Row
          className={`editing ${
            this.state.editing_left ? 'edit-left' : '' || this.state.editing_right ? 'edit-right' : ''
          }`}
        >
          <Col className={`edit-wrapper ${!this.state.editing_left ? 'edit-wrapper-left' : ''}`} sm="6">
            {this.state.edit_section_left && (
              <SectionDatumUpdate
                facility_uuid={this.props.facility.uuid}
                hideEditBoxLeft={this.hideEditBoxLeft}
                section_datum_uuid={this.state.update_section_uuid}
                locale={this.props.locale}
                match={this.props.match}
              />
            )}
          </Col>
          <Sections editContentLeft={this.editContentLeft} {...this.props} />
          <Col sm="6">
            <Row className="with-seperator">
              <Col sm="12">
                <h2 className="headline">Allgemeine Informationen zum Betrieb</h2>
              </Col>
            </Row>
            <hr className="seperator" />
            <Information {...this.props} editContentRight={this.editContentRight} />
          </Col>
          <Col className={`edit-wrapper ${this.state.editing_right ? '' : 'edit-wrapper-right'}`} sm="6">
            <InformationUpdate {...this.props} hideEditBoxRight={this.hideEditBoxRight} type={this.state.type} />
          </Col>
        </Row>
      </div>
    )
  }
}

const CollectionDates = ({ facility: { collection_desired_until, data_collected_at }, locale }) => {
  const dates = []

  if (collection_desired_until !== null) {
    dates.push({
      key: 'collection_desired_until',
      value: DD_MM_YYYY(collection_desired_until)
    })

    if (data_collected_at !== null) {
      dates.push({
        key: 'data_collected_at',
        value: DD_MM_YYYY__HH_mm(data_collected_at)
      })
    }
  }

  return (
    <React.Fragment>
      {dates.length ? (
        <Row>
          {dates.map((date, index) => (
            <CollectionDate key={index} date={date} locale={locale} cols={dates.length} />
          ))}
        </Row>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

const CollectionDate = ({ date, locale, cols }) => {
  return (
    <Col sm={12 / cols}>
      <div className="align-items-center box box-small d-flex flex-row mt-0">
        <h2 className="h4 mb-0 text-secondary">{locale[date.key]}</h2>
        <div className="align-items-center d-flex h3 ml-auto mb-0 text-right">
          <Badge className="text-primary-dark font-weight-normal p-3" color="success">
            {date.value}
          </Badge>
        </div>
      </div>
    </Col>
  )
}

export default withApollo(Facility)
