import { withApollo } from '@apollo/client/react/hoc'

import { auth } from '../../../config/auth'
// additional components
import { TokenAuth } from '../../../helpers/token-auth'

const Logout = ({ client, history }) => {
  //this is a dirty hack to make a DELETE request to the old backend.
  // Because there exists the old rails backend, a session cookie will be created when logging in via token auth.
  // To delete this cookie after logout, the logged in user have to deauth via the old backend too.

  const pathArray = auth.uri.split('/')
  auth.options.body = JSON.stringify(TokenAuth.getCredentials())
  auth.options.method = 'DELETE'
  fetch(`${auth.uri}/sign_out`, auth.options)
    .then(() => client.resetStore())
    .then(() => TokenAuth.logout())
    .then(() => history.push('/login'))
    .then(_ => window.location.reload())
  return null
}

export default withApollo(Logout)
