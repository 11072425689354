import React, { Fragment } from 'react'

import { Badge, Col, Collapse, Row, Table, UncontrolledTooltip } from 'reactstrap'

import { faAngleDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Create from './create'
import Update from './update'
import UpdateData from './update-data'

class ContactPersonCertification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ collapse: !this.state.collapse })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'Closed' })

  allUsersAttached = () =>
    !!this.props.facility.collector && !!this.props.facility.dsft_user && !!this.props.facility.license_owner_user

  render() {
    const {
      toggle,
      onEntering,
      onExiting,
      props: { uuid, permissions, locale, facility, match },
      state: { status, collapse }
    } = this

    return (
      <div className="collapse-container">
        <div className={`collapse-header btn d-flex align-items-center flex-row clearfix ${status}`} onClick={toggle}>
          <h2 className="h4 collapse-headline">
            <span className="collapse-headline-text">{locale.contact_person_certification.headline}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h2>
          <div className="align-items-center d-flex ml-auto mb-0 text-right">
            {!this.allUsersAttached() && (
              <Badge
                className="text-primary-dark font-weight-normal text-right"
                color="warning"
                children="Nicht vollständig"
              />
            )}
          </div>
        </div>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          <Row>
            <Col size={{ sm: 12 }}>
              <UserTable
                match={match}
                locale={locale}
                id={'contact_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.contact_user}
                user={facility.contact_user}
                options={facility.possible_contact_users}
                isUser={facility.contact_user}
                contact_person={facility.base_data.contact_person}
                isFacilityOwner
                hasPermission={false}
                permissions={facility.permissions}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ sm: 6 }}>
              <UserTable
                match={match}
                locale={locale}
                id={'collector_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.collector}
                user={facility.collector}
                isUser={!!facility.collector}
                options={facility.possible_collectors}
                hasPermission={facility.permissions.change_collector}
              />
            </Col>

            <Col size={{ sm: 6 }}>
              <UserTable
                match={match}
                locale={locale}
                id={'secondary_collector_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.secondary_collector}
                user={facility.secondary_collector}
                isUser={!!facility.secondary_collector}
                options={facility.possible_collectors}
                hasPermission={facility.permissions.change_secondary_collector}
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ sm: 6 }}>
              <UserTable
                match={match}
                locale={locale}
                id={'dsft_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.dsft_user}
                user={facility.dsft_user}
                isUser={!!facility.dsft_user}
                options={facility.possible_dsft_users}
                hasPermission={facility.permissions.change_dsft_user}
              />
            </Col>
            <Col size={{ sm: 6 }}>
              <UserTable
                match={match}
                locale={locale}
                id={'license_owner_user_id'}
                uuid={facility.uuid}
                headline={locale.contact_person_certification.license_owner_user}
                user={facility.license_owner_user}
                isUser={!!facility.license_owner_user}
                options={facility.possible_license_owner_users}
                hasPermission={facility.permissions.change_license_owner_user}
              />
            </Col>
          </Row>
        </Collapse>
      </div>
    )
  }
}

const UserTable = ({
  match,
  locale,
  user,
  headline,
  isUser,
  id,
  isFacilityOwner,
  options,
  uuid,
  hasPermission,
  contact_person,
  permissions
}) => {
  return (
    <Table striped bordered responsive>
      <thead className="thead-light">
        <tr>
          <th>
            {headline || ''}
            {isUser && (
              <Fragment>
                {' '}
                <Badge id={id} color="primary">
                  <FontAwesomeIcon icon={faUser} />
                </Badge>
                <UncontrolledTooltip placement="right" target={id}>
                  Benutzer
                </UncontrolledTooltip>
              </Fragment>
            )}

            {isFacilityOwner && (
              <div className="fa-pull-right">
                {!isUser && permissions.create_contact_user && (
                  <Create
                    match={match}
                    locale={locale}
                    contact_person={contact_person}
                    headline={headline}
                    user={user}
                    isUser={isUser}
                    options={options}
                    id={id}
                    uuid={uuid}
                  />
                )}{' '}
                {permissions.change_contact_user && (
                  <Update
                    match={match}
                    locale={locale}
                    contact_person={contact_person}
                    headline={headline}
                    user={user}
                    isUser={isUser}
                    options={options}
                    id={id}
                    uuid={uuid}
                    unlinkingAllowed
                  />
                )}{' '}
                {!isUser && permissions.update_base_data && (
                  <UpdateData
                    match={match}
                    locale={locale}
                    contact_person={contact_person}
                    headline={headline}
                    user={user}
                    isUser={isUser}
                    options={options}
                    id={id}
                    uuid={uuid}
                  />
                )}{' '}
              </div>
            )}

            {!isFacilityOwner && hasPermission && (
              <div className="fa-pull-right">
                <Update
                  match={match}
                  headline={headline}
                  user={user}
                  isUser={isUser}
                  options={options}
                  id={id}
                  uuid={uuid}
                />
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {user?.salutation?.label || contact_person?.salutation?.label || ''}{' '}
            {user?.first_name || contact_person?.first_name || ''} {user?.last_name || contact_person?.last_name || ''}
            &nbsp;
            <br />
            {user?.email || contact_person?.email || ''}&nbsp;
            <br />
            {user?.phone || contact_person?.phone || ''}&nbsp;
            <br />
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default ContactPersonCertification
