import React, { Fragment } from 'react'

import { Badge, Button, Collapse, Table, Tooltip, UncontrolledTooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TableField, TranslatedTableField } from '../partials'

class BaseData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  render = () => {
    const {
      props: { facility, locale, editContentRight },
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open }
    } = this

    return (
      <div className="collapse-container">
        <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
          <h3 className="collapse-headline">
            <span className="collapse-headline-text">{locale.base_data.plural}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
          {facility.permissions.update_base_data && (
            <div className="list-actions">
              <Button
                className="btn-transparent btn-icon-only"
                color={'link'}
                onClick={editContentRight}
                id={`base_data`}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Tooltip delay={500} placement="top" isOpen={tooltip_open} target={`base_data`} toggle={toggleTooltip}>
                {locale.edit}
              </Tooltip>
            </div>
          )}
        </div>
        <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
          <Table striped responsive bordered>
            <tbody>
              <TableField label={locale.base_data.name_de} value={facility?.base_data?.name_de || ''} />
              <TableField label={locale.base_data.name_en} value={facility?.base_data?.name_en || ''} />
              <TableField label={locale.base_data.description_de} value={facility?.base_data?.description_de || ''} />
              <TableField label={locale.base_data.description_en} value={facility?.base_data?.description_en || ''} />
              <TableField label={locale.base_data.fax} value={facility?.base_data?.fax || ''} />
              <TableField label={locale.base_data.email} value={facility?.base_data?.email || ''} />
              <TableField label={locale.base_data.phone} value={facility?.base_data?.phone || ''} />
              <TableField
                label={locale.base_data.website}
                value={
                  facility?.base_data?.website && (
                    <a href={facility.base_data.website} target="_blank">
                      {facility.base_data.website}
                    </a>
                  )
                }
              />
              <TranslatedTableField
                field={facility?.base_data?.metaDescription}
                translationKey="base_data.metaDescription"
              />
              <TableField
                label={locale.organizations}
                value={
                  facility?.base_data?.organizations?.map(({ short_name, id, name }) => (
                    <Fragment key={id}>
                      <UncontrolledTooltip placement="top" target={`organization_badge_${id}`}>
                        {name}
                      </UncontrolledTooltip>
                      <Badge key={id} color="primary" id={`organization_badge_${id}`} className="mr-2">
                        {short_name}
                      </Badge>
                    </Fragment>
                  )) || ''
                }
              />
            </tbody>
          </Table>
        </Collapse>
      </div>
    )
  }
}

export default BaseData
