import React from 'react'

import { Button, Col, Row } from 'reactstrap'

import AccountingInformationForm from './types/accounting_information_form'
import AddressForm from './types/address-form'
import BaseDataForm from './types/base_data-form'
import BundleInformationForm from './types/bundle_information/form'
import ChargesForm from './types/charges-form'
import ConfidentialBaseDataForm from './types/confidential_base_data-form'
import FacilityKindsForm from './types/facility_kinds-form'
import PartnerGatewayForm from './types/partner_gateway-form'
import SearchCriteriaForm from './types/search_criteria-form'
import ThirdPartyIdsForm from './types/third_party_ids_form'
import TouristicDataForm from './types/touristic_data-form'

const FormSwitch = props => {
  switch (props.type) {
    case 'base_data':
      return <BaseDataForm {...props} />
    case 'address':
      return <AddressForm {...props} />
    case 'charges':
      return <ChargesForm {...props} />
    case 'touristic':
      return <TouristicDataForm {...props} />
    case 'basic_information':
      return <ConfidentialBaseDataForm {...props} />
    case 'search_criteria':
      return <SearchCriteriaForm {...props} />
    case 'facility_kinds':
      return <FacilityKindsForm {...props} />
    case 'partner_gateway':
      return <PartnerGatewayForm {...props} />
    case 'third_party_ids':
      return <ThirdPartyIdsForm {...props} />
    case 'accounting_information':
      return <AccountingInformationForm {...props} />
    case 'bundle_information':
      return <BundleInformationForm {...props} />
    default:
      return <p>template missing.</p>
  }
}

const Update = props => (
  <React.Fragment>
    <Row className="my-0">
      <Col sm="6">
        <h2 className="mb-0">{props.locale.editing}</h2>
      </Col>
      <Col sm="6" className="text-right">
        <Button color="link" onClick={props.hideEditBoxRight}>
          {props.locale.close}
        </Button>
      </Col>
    </Row>
    <hr className="seperator" />
    {FormSwitch(props)}
  </React.Fragment>
)

export default Update
