import React from 'react'

import { Alert, Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap'

import { graphql, withApollo } from '@apollo/client/react/hoc'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RadioButtons from 'components/modules/radio-switch/radio-buttons'
import { deepNestInObjectsWithKeys } from 'helpers/object'
import update from 'immutability-helper'

import { getFacilityKinds, getFormVersions, updateFacilityKind } from './facility_kinds.graphql'

import Loading from '../../../helper/loading/loading'
import ValidatedInput from '../../../modules/inputs/validated-input'
import FacilityKindParentSelect from '../../../modules/selects/facility_kind_parent'
import SectionsForFormVersionSelect from '../../../modules/selects/sections_for_form_version'

class FormUpdate extends React.Component {
  constructor(props) {
    super(props)

    //the section_ids are spread over form_versions.

    let form_version_ids = []
    let selected_sections = {}

    this.props.facility_kind.sections.map((section, index) =>
      form_version_ids.push({ form_version_id: section.form_version.id, section_id: section.id })
    )
    form_version_ids.map(version => {
      Array.isArray(selected_sections[`section_ids_${version.form_version_id}`])
        ? null
        : (selected_sections[`section_ids_${version.form_version_id}`] = [])
      selected_sections[`section_ids_${version.form_version_id}`].push(version.section_id)
    })
    const defaultState = {
      selected_sections: selected_sections,
      variables: {
        id: this.props.facility_kind.id,
        facility_kind: {
          name_de: this.props.facility_kind?.name_de || '',
          name_en: this.props.facility_kind?.name_en || '',
          parent_kind_id: this.props.facility_kind?.parent_kind?.id || '',
          section_ids: this.props.facility_kind.sections.map(section => section.id),
          relevantForVolumeUnitCalculation: this.props.facility_kind?.relevantForVolumeUnitCalculation
        }
      }
    }

    this.state = defaultState
  }

  onChange = event => {
    if (event.target) {
      const fragments = event.target.id.split('.')
      const variables = deepNestInObjectsWithKeys(event.target.value, ...fragments, '$set')

      this.setState(update(this.state, { variables }))
    } else {
      let selected = []
      event.map(item => selected.push(item.target.value))

      this.setState(
        update(this.state, {
          selected_sections: {
            [event[0].target.id]: {
              $set: selected
            }
          }
        })
      )
    }
  }
  onError = ({ graphQLErrors }) =>
    this.setState({
      error_msg: graphQLErrors[0].message,
      errors: graphQLErrors[0].errors
    })

  submit = () => {
    let selected = []
    for (let key in this.state.selected_sections) {
      // skip loop if the property is from prototype
      if (!this.state.selected_sections.hasOwnProperty(key)) continue

      const property = this.state.selected_sections[key]
      property.map(item => selected.push(item))
    }

    let { variables } = this.state

    variables.facility_kind.section_ids = selected

    this.props.client
      .mutate({
        mutation: updateFacilityKind,
        variables,
        refetchQueries: [
          {
            query: getFacilityKinds
          }
        ]
      })
      .then(result => this.props.toggleModal())
      .catch(error => this.onError(error))
  }

  render() {
    const {
      locale,
      toggleModal,
      data: { error, loading, form_versions }
    } = this.props
    const { errors, error_msg, variables, selected_sections } = this.state
    const { submit, onChange } = this

    return (
      <Form>
        {errors ? <Alert color="danger">{error_msg}</Alert> : ''}
        <FormGroup>
          <Container>
            <Row>
              <ValidatedInput
                errors={errors}
                id={'facility_kind.name_de'}
                locale={locale}
                onChange={onChange}
                variables={variables}
              />
              <ValidatedInput
                errors={errors}
                id={'facility_kind.name_en'}
                locale={locale}
                onChange={onChange}
                variables={variables}
              />
            </Row>
            <Row>
              <Col sm={'2'} className="text-right">
                <Label className="control-label" or={'facility_kind.parent_kind_id'}>
                  {locale.facility_kind.parent_kind_id}
                </Label>
              </Col>
              <Col sm={'4'}>
                <FacilityKindParentSelect
                  errors={errors}
                  id={'facility_kind.parent_kind_id'}
                  locale={locale}
                  onChange={onChange}
                  variables={variables}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="2" className="text-right">
                <Label className="control-label" for="facility_kind.relevantForVolumeUnitCalculation">
                  {locale.facility_kind.relevantForVolumeUnitCalculation}
                </Label>
              </Col>
              <Col sm="4">
                <RadioButtons
                  id={'facility_kind.relevantForVolumeUnitCalculation'}
                  locale={locale}
                  onChange={onChange}
                  variables={variables}
                />
              </Col>
            </Row>
          </Container>
        </FormGroup>
        {loading ? (
          <Loading />
        ) : (
          <FormVersionsAndSections
            errors={errors}
            form_versions={form_versions}
            locale={locale}
            onChange={onChange}
            selected_sections={selected_sections}
            variables={variables}
          />
        )}
        <FormGroup className="form-action">
          <Container>
            <Row>
              <Col sm="5">
                <a className="link-btn" onClick={toggleModal} href="#">
                  {locale.cancel}
                </a>
              </Col>
              <Col sm="7" className="text-right">
                <Button className="btn-labeled" color="primary-light" onClick={submit}>
                  <FontAwesomeIcon className="icon-prepend" icon={faSave} />
                  <span>{locale.save}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </FormGroup>
      </Form>
    )
  }
}

export const FormVersionsAndSections = ({ form_versions, errors, variables, onChange, selected_sections, locale }) => (
  <FormGroup className="form-action">
    <h3>Standard-Teilbereiche</h3>
    {form_versions.map((form_version, index) => (
      <FormVersionAndSections
        errors={errors}
        form_version={form_version}
        index={index}
        key={index}
        locale={locale}
        onChange={onChange}
        selected_sections={selected_sections}
        variables={variables}
      />
    ))}
  </FormGroup>
)

const FormVersionAndSections = ({ form_version, errors, variables, onChange, index, selected_sections, locale }) => (
  <Container>
    <Row>
      <Col sm={'2'} className="text-right">
        <Label
          className="control-label"
          for={`section_ids_${index}`}
        >{`${locale.version}: ${form_version.name}`}</Label>
      </Col>
      <Col sm={'4'}>
        <SectionsForFormVersionSelect
          errors={errors}
          id={`section_ids_${form_version.id}`}
          onChange={onChange}
          sections={form_version.sections}
          selected_sections={selected_sections}
          variables={variables}
        />
      </Col>
    </Row>
    <br />
  </Container>
)

export default withApollo(
  graphql(getFormVersions, {
    options: () => ({
      notifyOnNetworkStatusChange: true
    })
  })(FormUpdate)
)
