// additional components
import React, { Component } from 'react'

import { Link } from 'react-router-dom'
import { Badge, Button, Card, CardText, CardTitle, Col, Collapse, Label, Row, Tooltip } from 'reactstrap'

import { Query } from '@apollo/client/react/components'
import { faAngleDown, faComment, faEdit, faExchangeAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getFormDataForSectionDatum } from '../facility.graphql'

import { checkIfNull } from '../../../helper/helper-functions.jsx'
import Loading from '../../../helper/loading/loading.jsx'
import CopyFormDatumModal from '../../../modules/modal/copyFormDatumModal'
import SectionImagesModal from '../../../modules/modal/createSectionImagesModal'
import DeleteFormDatumModal from '../../../modules/modal/deleteFormDatumModal'
import DeleteSectionDatumModal from '../../../modules/modal/deleteSectionDatumModal'
import PossibleFormsSelect from '../../../modules/selects/possible-forms-select/possible_forms'
import Images from '../../facility/sections/images/images'

const Forms = ({ form, locale, facility_uuid, client, history, section_datum_uuid, permissions }) => (
  <React.Fragment>
    <Row className="with-seperator">
      <Col sm="5" lg="6">
        <h4 className="headline">
          {form.form_sheet_number}&nbsp;{form.form_name} {!form.in_use && '(deaktiviert)'}
        </h4>
      </Col>
      <Col sm="7" lg="6" className="text-right col-align-bottom">
        {permissions.update_barrier_free_data ? (
          <Button
            size={`sm`}
            className="btn btn-labeled"
            color={'primary-light'}
            onClick={() =>
              history.location.pathname.includes('offline-facility')
                ? history.push(`/offline-formdatum/new/${form.id}/${section_datum_uuid}/${facility_uuid}`)
                : history.push(`/formdatum/new/${form.id}/${section_datum_uuid}/${facility_uuid}`)
            }
          >
            <FontAwesomeIcon className="icon-prepend" icon={faPlus} />
            <span>{locale.add_form}</span>
          </Button>
        ) : null}
      </Col>
    </Row>
    <hr className="seperator seperator-small" />
    <ul>
      {form.form_data.map(form_datum => (
        <FormDatum
          history={history}
          client={client}
          facility_uuid={facility_uuid}
          form_datum={form_datum}
          key={form_datum.uuid}
          locale={locale}
          permissions={permissions}
          section_datum_uuid={section_datum_uuid}
        />
      ))}
    </ul>
  </React.Fragment>
)

const FormDatum = ({ form_datum, facility_uuid, client, locale, history, section_datum_uuid }) => (
  <li>
    {form_datum.viewable && (
      <Link
        to={
          history.location.pathname.includes('offline')
            ? `/offline-formdatum/edit/${form_datum.uuid}`
            : `/formdatum/edit/${form_datum.uuid}`
        }
      >
        {form_datum.name_de}
      </Link>
    )}
    {!form_datum.changeable && !form_datum.viewable && form_datum.name_de}

    {form_datum.changeable && (
      <CopyFormDatumModal
        client={client}
        facility_uuid={facility_uuid}
        name={form_datum.name_de}
        form_datum_uuid={form_datum.uuid}
        locale={locale}
        section_datum_uuid={section_datum_uuid}
      />
    )}
    {form_datum.deletable && (
      <DeleteFormDatumModal
        client={client}
        facility_uuid={facility_uuid}
        name={form_datum.name_de}
        form_datum_uuid={form_datum.uuid}
        locale={locale}
        section_datum_uuid={section_datum_uuid}
      />
    )}
  </li>
)

class DragableSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      tooltip_form_count_open: false,
      tooltip_edit_open: false,
      tooltip_comment_open: false,
      tooltip_exchange_open: false,
      status: 'closed'
    }
  }

  getForms = form_data => {
    let group_by = 'form_id'

    let forms = form_data.reduce((groups, item) => {
      let val = item[group_by]
      groups[val] = groups[val] || {
        id: val,
        form_name: item.form_name,
        form_sheet_number: item.form_sheet_number,
        form_data: [],
        in_use: item.form.in_use,
        order_no: item.form.order_no
      }
      groups[val].form_data.push(item)
      return groups
    }, {})

    return Object.values(forms).sort((a, b) => a.order_no - b.order_no)
  }

  getExchangeText = () =>
    this.props.alternative_section_data.map(alternative_section_datum => alternative_section_datum?.name_de).join(', ')

  toggleCollapse = () =>
    this.setState({
      collapse: !this.state.collapse
    })

  toggleTooltipComment = () =>
    this.setState({
      tooltip_comment_open: !this.state.tooltip_comment_open
    })

  toggleTooltipExchange = () =>
    this.setState({
      tooltip_exchange_open: !this.state.tooltip_exchange_open
    })

  toggleTooltipEdit = () =>
    this.setState({
      tooltip_edit_open: !this.state.tooltip_edit_open
    })

  toggleTooltipFormCount = () =>
    this.setState({
      tooltip_form_count_open: !this.state.tooltip_form_count_open
    })

  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })

  setNewFormdatum = event => {
    this.setState({ new_formdatum_uuid: event.value })
    this.props.history.push(`/formdatum/edit/${event.value}`)
  }

  render = () => {
    const {
      alternative_section_data,
      changeable,
      children,
      client,
      comment,
      deletable,
      draggable,
      editContentLeft,
      facility_uuid,
      form_data_count,
      form_data_creatable,
      history,
      locale,
      name_de,
      permissions,
      section_datum_uuid,
      uuid,
      match
    } = this.props

    const {
      collapse,
      status,
      tooltip_comment_open,
      tooltip_edit_open,
      tooltip_exchange_open,
      tooltip_form_count_open
    } = this.state

    const {
      props,
      getExchangeText,
      getForms,
      onEntering,
      onExiting,
      toggleCollapse,
      toggleTooltipComment,
      toggleTooltipEdit,
      toggleTooltipExchange,
      toggleTooltipFormCount
    } = this

    return (
      <div className={`collapse-container ${draggable ? 'collapse-draggable' : ''}`}>
        <div
          className={`collapse-header btn clearfix ${status} ${
            permissions.update_barrier_free_data && changeable ? '' : 'pr-3'
          }`}
          onClick={toggleCollapse}
        >
          <h3 className="collapse-headline">
            <span className="collapse-headline-text">{name_de}</span>
            <span className="collapse-icon icon-append">
              <FontAwesomeIcon size="2x" icon={faAngleDown} />
            </span>
          </h3>
          <div className="list-actions">
            {comment ? (
              <React.Fragment>
                <Button
                  className="btn-transparent margin-left-sm btn-icon-only"
                  color="link"
                  id={`tooltip-comment_${uuid}`}
                >
                  <FontAwesomeIcon icon={faComment} />
                </Button>
                <Tooltip
                  delay={500}
                  isOpen={tooltip_comment_open}
                  placement="top"
                  target={`tooltip-comment_${uuid}`}
                  toggle={toggleTooltipComment}
                >
                  {locale.tooltip_comment}
                </Tooltip>
              </React.Fragment>
            ) : (
              ''
            )}

            {alternative_section_data.length ? (
              <React.Fragment>
                <Button
                  className="btn-transparent margin-left-sm btn-icon-only"
                  color="link"
                  id={`tooltip-exchange_${uuid}`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </Button>
                <Tooltip
                  delay={500}
                  isOpen={tooltip_exchange_open}
                  placement="top"
                  target={`tooltip-exchange_${uuid}`}
                  toggle={toggleTooltipExchange}
                >
                  {getExchangeText()}
                </Tooltip>
              </React.Fragment>
            ) : (
              ''
            )}

            <span className="btn-transparent margin-left-sm btn-icon-only">
              <Badge id={`tooltip-form-count_${uuid}`} pill>
                {form_data_count}
              </Badge>
              <Tooltip
                delay={500}
                placement="top"
                isOpen={tooltip_form_count_open}
                target={`tooltip-form-count_${uuid}`}
                toggle={toggleTooltipFormCount}
              >
                {locale.form_count}
              </Tooltip>
            </span>

            {permissions.update_barrier_free_data && changeable ? (
              <React.Fragment>
                <Button
                  className="btn-transparent btn-icon-only margin-left-sm"
                  color={'link'}
                  onClick={editContentLeft}
                  id={`tooltip-edit_${uuid}`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_edit_open}
                  target={`tooltip-edit_${uuid}`}
                  toggle={toggleTooltipEdit}
                >
                  {locale.edit}
                </Tooltip>
              </React.Fragment>
            ) : null}

            {permissions.update_barrier_free_data && deletable && (
              <DeleteSectionDatumModal
                match={match}
                facility_uuid={facility_uuid}
                uuid={uuid}
                client={client}
                name={name_de}
                locale={locale}
              />
            )}

            {permissions.update_barrier_free_data && changeable ? <React.Fragment>{children}</React.Fragment> : null}
          </div>
        </div>
        <Collapse className="collapse-content" isOpen={collapse} onEntering={onEntering} onExiting={onExiting}>
          {comment ? (
            <Card body>
              <CardTitle>{locale.comment}</CardTitle>
              <CardText>{checkIfNull(comment)}</CardText>
            </Card>
          ) : (
            ''
          )}
          <Query
            query={getFormDataForSectionDatum}
            variables={{
              uuid: section_datum_uuid
            }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) return <Loading />
              if (error) return JSON.stringify(error)

              return getForms(data.section_datum.form_data).map(form => (
                <Forms
                  client={client}
                  facility_uuid={facility_uuid}
                  form={form}
                  history={history}
                  key={form.id}
                  locale={locale}
                  permissions={permissions}
                  section_datum_uuid={section_datum_uuid}
                />
              ))
            }}
          </Query>
          {permissions.update_barrier_free_data && form_data_creatable ? (
            <Row>
              <Col sm="6">
                {form_data_creatable ? (
                  <React.Fragment>
                    <Label className="control-label justify-content-start" for="add-form">
                      Formular hinzufügen
                    </Label>
                    <PossibleFormsSelect {...props} />
                  </React.Fragment>
                ) : null}
              </Col>

              <Col className="d-flex align-items-end justify-content-end" sm="6">
                <SectionImagesModal client={client} locale={locale} modal_size={'lg'}>
                  <Images locale={locale} client={client} uuid={section_datum_uuid} />
                </SectionImagesModal>
              </Col>
            </Row>
          ) : null}
        </Collapse>
      </div>
    )
  }
}

export default DragableSection
