import React from 'react'

import AccountingInformation from './types/accounting_information'
import Address from './types/address'
import AssetDownloads from './types/asset_downloads'
import BaseData from './types/base_data'
import BundleInformation from './types/bundle_information'
import Charges from './types/charges'
import ConfidentialBaseData from './types/confidential_base_data'
import FacilityKinds from './types/facility_kinds'
import LivePdf from './types/live-pdf'
import PartnerGateway from './types/partner_gateway'
import SearchCriteria from './types/search_criteria'
import ThirdPartyIds from './types/third_party_ids'
import TouristicData from './types/touristic_data'

export const Information = props => (
  <React.Fragment>
    <BaseData {...props} />
    <Address {...props} />
    <TouristicData {...props} />
    <ConfidentialBaseData {...props} />
    <BundleInformation {...props} />
    <Charges {...props} />
    <LivePdf {...props} />
    <AssetDownloads {...props} />
    <SearchCriteria {...props} />
    <FacilityKinds {...props} />
    <ThirdPartyIds {...props} />
    {props.facility.accounting_information && props.facility.permissions.update_accounting_information && (
      <AccountingInformation {...props} />
    )}
    <PartnerGateway {...props} />
  </React.Fragment>
)
