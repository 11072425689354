import React, { Fragment } from 'react'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Alert, Col, Row } from 'reactstrap'

import * as Sentry from '@sentry/react'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'

import DragHandle from 'components/modules/sortable/DragHandle'

import { getFacilitySectionData, reorderSectionDatum } from '../facility.graphql'

import Loading from '../../../helper/loading/loading'
import Create from './create'
import DragableSection from './dragable-section'
import moveSectionDatum from './move_section_datum'
import WithoutSection from './without-section'

const Sections = props => (
  <Col sm="6">
    <Row className="with-seperator">
      <Col sm="4" lg="6">
        <h2 className="headline">{props.locale.sections}</h2>
      </Col>
      <Col sm="8" lg="6" className="text-right">
        {props.facility.permissions.update_barrier_free_data && props.facility.section_data_creatable && (
          <Create facility_uuid={props.facility.uuid} locale={props.locale} match={props.match} />
        )}
      </Col>
    </Row>
    <hr className="seperator" />
    <Query
      query={getFacilitySectionData}
      variables={{
        uuid: props.facility.uuid,
        version_number: props.match.params.version_number || ''
      }}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) return <Loading />
        if (error) return JSON.stringify(error)

        return <SectionsView {...props} section_data={[...data.facility.section_data]} />
      }}
    </Query>
  </Col>
)

class SectionsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reordering: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.section_data) === JSON.stringify(state.prevPropsSectionData)) return null

    return {
      ...state,
      prevPropsSectionData: props.section_data,
      section_data: props.section_data.sort((a, b) => a.position - b.position)
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const uuid = this.state.section_data[oldIndex].uuid
    const position = this.state.section_data[newIndex].position

    this.setState(
      {
        ...this.state,
        section_data: moveSectionDatum({
          section_data: this.state.section_data,
          uuid,
          position
        })
      },
      () => {
        this.mutateReorder(uuid, position)
      }
    )
  }

  mutateReorder = (uuid, position) => {
    this.setState({
      ...this.state,
      reordering: true
    })

    this.props.client
      .mutate({
        mutation: reorderSectionDatum,
        variables: {
          uuid,
          position
        }
      })
      .then(result => {
        const updatedSectionData = this.state.section_data.map(section_datum => ({
          ...section_datum,
          position: result.data.reorder_section_datum.facility.section_data.filter(
            ({ uuid }) => uuid === section_datum.uuid
          )[0]?.position
        }))

        this.setState({
          ...this.state,
          section_data: updatedSectionData.sort((a, b) => a.position - b.position),
          reordering: false
        })
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        this.setState({
          ...this.state,
          reordering: false
        })
      })
  }

  render = () => {
    let {
      onSortEnd,
      props: { history, client, locale, editContentLeft, facility, match },
      state: { section_data }
    } = this

    return (
      <Fragment>
        {facility.duplication_jobs_running ? (
          <Row>
            <Col>
              <Alert color="warning">{locale.duplication_jobs_running}</Alert>
            </Col>
          </Row>
        ) : null}
        <div className="collapse-wrapper">
          <SortableList
            lockAxis="y"
            useDragHandle
            lockToContainerEdges
            loading={this.state.reordering}
            helperClass="sortable-helper"
            {...{
              client,
              editContentLeft,
              facility,
              history,
              locale,
              onSortEnd,
              match,
              section_data
            }}
          />
          {facility?.form_data_without_section_count > 0 && (
            <WithoutSection
              facility_uuid={facility.uuid}
              facility_version={facility.version_number || ''}
              count={facility.form_data_without_section_count}
              editContentLeft={editContentLeft}
              locale={locale}
              permissions={facility.permissions}
              match={match}
            />
          )}
        </div>
      </Fragment>
    )
  }
}

const SortableList = SortableContainer(
  ({ editContentLeft, client, locale, history, facility, match, section_data, loading }) => (
    <div className="sortable-list">
      {section_data.map((item, index) => (
        <SortableItem
          facility_uuid={facility.uuid}
          key={`item-${item.name_de}`}
          permissions={facility.permissions}
          {...{
            facility,
            match,
            client,
            editContentLeft,
            history,
            index,
            item,
            locale,
            loading
          }}
        />
      ))}
    </div>
  )
)

const SortableItem = SortableElement(
  ({ facility, item, editContentLeft, client, locale, history, facility_uuid, permissions, match, loading }) => (
    <DragableSection
      draggable={true}
      form_data_creatable={item.form_data_creatable}
      key={item.uuid}
      section_datum_uuid={item.uuid}
      {...{
        ...item,
        facility,
        client,
        editContentLeft,
        facility_uuid,
        history,
        locale,
        permissions,
        match
      }}
    >
      <DragHandle className="btn-seperator-left btn-transparent" loading={loading} />
    </DragableSection>
  )
)

export default withApollo(Sections)
