import { Fragment } from 'react'

import { Badge, Progress } from 'reactstrap'

import { FormattedDateTime } from 'components/ui/genericComponents/formattedDateTime'
import { withLocale } from 'locale'
import PropTypes from 'prop-types'

import ActionButtons from './actionButtons'
import CertificatePropertyImages from './certificatePropertyImages'
import EmailLink from './emailLink'
import FacilityLinkWithBundleInfo from './facilityLinkWithBundleInfo'
import PhoneLink from './phoneLink'

const TableRowForFacility = ({ locale, facility }) => {
  const renderRegionBadges = () =>
    facility.base_data.regions.map((region, index) => (
      <Badge key={region.id} color="primary" className={index > 0 ? 'ml-1' : ''}>
        {region.name_de}
      </Badge>
    ))

  const renderBundles = () =>
    [...facility.bundleInformation.bundledBy.nodes].map((bundlingFacility, index) => (
      <Fragment key={index}>
        {index > 0 && <br />}
        <FacilityLinkWithBundleInfo key={bundlingFacility.uuid} facility={bundlingFacility} />
      </Fragment>
    ))

  return (
    <tr>
      {/* remove vertical padding from this cell, because the button already adds plenty */}
      <td className="py-0">
        <ActionButtons facilityUuid={facility.uuid} />
      </td>
      <td>{facility.license_owner?.name}</td>
      <td>
        {/* TODO: this should probably be its own component. Currently partially copy-pasted from the workflow progress bar */}
        <Progress
          className="progress-compact bg-white border"
          value={facility.current_workflow_state.progress}
          style={{ width: '32rem' }}
        >
          <small className="progress-text">{locale.certification_states[facility.current_workflow_state.state]}</small>
        </Progress>
      </td>
      <td className="text-center">
        <Badge>{facility.certification_data.audit_order_number}</Badge>
      </td>
      <td>
        <strong>
          <FacilityLinkWithBundleInfo facility={facility} />
        </strong>
      </td>
      <td>{facility.base_data.address.city}</td>
      <td>{renderRegionBadges()}</td>
      <td>{renderBundles()}</td>
      <td>{facility.form_data_count}</td>
      <td>{(facility.base_data.contact_user || facility.base_data.contact_person)?.full_name}</td>
      <td>
        <EmailLink
          email={(facility.base_data.contact_user || facility.base_data.contact_person || facility.base_data)?.email}
        />
      </td>
      <td>
        <PhoneLink
          phone={(facility.base_data.contact_user || facility.base_data.contact_person || facility.base_data)?.phone}
        />
      </td>
      <td>{facility.collector?.full_name}</td>
      <td>
        <FormattedDateTime ISO8601DateTime={facility.collection_desired_until} format="dd.MM.yyyy" />
      </td>
      <td>
        <FormattedDateTime ISO8601DateTime={facility.data_collected_at} />
      </td>
      <td>
        <Badge color={facility.training_completed ? 'success' : 'warning'} className="w-100">
          {facility.training_completed ? locale.training_completed : locale.training_pending}
        </Badge>
      </td>
      <td>
        <FormattedDateTime ISO8601DateTime={facility.certification_data.certified_to} format="MMMM yyyy" />
      </td>
      <td>
        <CertificatePropertyImages certification_data={facility.certification_data} />
      </td>
    </tr>
  )
}

const bundleFacilityPropTypesShape = {
  base_data: PropTypes.shape({
    name: PropTypes.shape({
      de: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  certification_data: PropTypes.shape({
    audit_order_number: PropTypes.string.isRequired
  }).isRequired
}

const facilityBundlePropTypes = PropTypes.shape({
  totalCount: PropTypes.number.isRequired,
  nodes: PropTypes.arrayOf(
    PropTypes.shape({
      ...bundleFacilityPropTypesShape,
      bundleInformation: PropTypes.shape({
        bundledBy: PropTypes.shape({
          totalCount: PropTypes.number.isRequired,
          nodes: PropTypes.arrayOf(PropTypes.shape(bundleFacilityPropTypesShape)).isRequired
        }).isRequired,
        bundledFacilities: PropTypes.shape({
          totalCount: PropTypes.number.isRequired,
          nodes: PropTypes.arrayOf(PropTypes.shape(bundleFacilityPropTypesShape)).isRequired
        }).isRequired
      }).isRequired
    })
  ).isRequired
}).isRequired

TableRowForFacility.propTypes = {
  facility: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    base_data: PropTypes.shape({
      name: PropTypes.shape({
        de: PropTypes.string.isRequired
      }).isRequired,
      address: PropTypes.shape({
        city: PropTypes.string
      }).isRequired,
      regions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name_de: PropTypes.string.isRequired
        })
      ).isRequired,
      contact_user: PropTypes.shape({
        full_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      contact_person: PropTypes.shape({
        full_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string
      }),
      email: PropTypes.string,
      phone: PropTypes.string
    }).isRequired,
    license_owner: PropTypes.shape({
      name: PropTypes.string.isRequired
    }),
    current_workflow_state: PropTypes.shape({
      state: PropTypes.oneOf([
        'billed',
        'start_recertification',
        'canceled',
        'certificate_send',
        'certificate_received',
        'collector_inquired',
        'collector_accepted',
        'collection_scheduled',
        'collection_running',
        'collection_finished',
        'data_checked',
        'draft_declined',
        'draft_confirmed',
        'owner_informed',
        'ready_for_collection',
        'ready_for_report',
        'signed_contract',
        'started',
        'inquire_collector',
        'decline_collection',
        'accept_collection'
      ]).isRequired,
      progress: PropTypes.number.isRequired
    }).isRequired,
    certification_data: PropTypes.shape({
      audit_order_number: PropTypes.string,
      certified_to: PropTypes.string,

      certificate_type: PropTypes.shape({
        key: PropTypes.string.isRequired
      }).isRequired,
      // TODO: these proptypes should be defined in the certificatePropertyImages component when it gets refactored. Then reference them here.
      properties: PropTypes.shape({
        deaf: PropTypes.oneOf([0, 1, 2]),
        mental: PropTypes.oneOf([0, 1, 2]),
        partially_deaf: PropTypes.oneOf([0, 1, 2]),
        partially_visual: PropTypes.oneOf([0, 1, 2]),
        visual: PropTypes.oneOf([0, 1, 2]),
        walking: PropTypes.oneOf([0, 1, 2]),
        wheelchair: PropTypes.oneOf([0, 1, 2])
      }).isRequired
    }).isRequired,
    bundleInformation: PropTypes.shape({
      bundledBy: facilityBundlePropTypes,
      bundledFacilities: facilityBundlePropTypes
    }).isRequired,
    form_data_count: PropTypes.number.isRequired,
    collection_desired_until: PropTypes.string,
    data_collected_at: PropTypes.string,
    training_completed: PropTypes.bool.isRequired
  }),
  locale: PropTypes.object.isRequired
}

export default withLocale(TableRowForFacility)
