import { forwardRef, useEffect, useRef } from 'react'

import ReactSelect, { components } from 'react-select'
import { VariableSizeList } from 'react-window'

import { faAngleDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import variables from 'components/app/_export.scss'
import { withLocale } from 'locale/'

const customTheme = theme => ({
  ...theme,
  borderRadius: '.3rem',
  colors: {
    ...theme.colors,
    primary: variables['theme-colors-primary'],
    primary25: variables['theme-colors-primary-light'],
    primary50: variables['theme-colors-primary-light'],
    primary75: variables['theme-colors-primary-light'],
    danger: variables['theme-colors-danger']
  }
})

const themeStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 2
  }),
  option: (provided, state) => ({
    ...provided,
    color: variables[`theme-colors-text-${state.isFocused || state.isSelected ? 'light' : 'dark'}`]
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: variables['theme-colors-input-border-color'],
    backgroundColor: variables[`theme-colors-input-bg${state.isDisabled ? '-disabled' : ''}`],
    height: state.getValue().length > 0 ? 'auto' : undefined,
    minHeight: 'unset',
    padding: 0
  }),
  multiValue: (provided, state) => ({
    ...provided,
    borderRadius: '2px',
    backgroundColor: variables['theme-colors-primary-light'],
    color: variables['theme-colors-text-light']
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    paddingRight: '6px',
    color: variables['theme-colors-text-light']
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    borderRight: `1px solid ${variables['theme-colors-text-light']}`,
    padding: '1px 2px',
    '&:hover,&:focus': {
      backgroundColor: variables['theme-colors-primary'],
      color: variables['theme-colors-text-light'],
      cursor: 'pointer'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const mergeStyles = (a, b) => ({
  ...a,
  ...(_ => {
    let out = {}

    Object.keys(b || {}).forEach(key => {
      out[key] = (provided, state) => {
        const customTheme = typeof a[key] == 'function' ? a[key](provided, state) : undefined

        return b[key](customTheme || provided, state)
      }
    })

    return out
  })()
})

const MultiValueContainer = props => {
  const Remove = props?.selectProps?.components?.MultiValueRemove || components.MultiValueRemove
  const Label = props?.selectProps?.components?.MultiValueLabel || components.MultiValueLabel

  return (
    <components.MultiValueContainer {...props}>
      <Remove {...props.children[1].props} />
      <Label {...props.children[0].props} />
    </components.MultiValueContainer>
  )
}

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <FontAwesomeIcon icon={faXmark} />
  </components.ClearIndicator>
)

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faAngleDown} />
  </components.DropdownIndicator>
)

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <FontAwesomeIcon icon={faXmark} className="mx-1" />
  </components.MultiValueRemove>
)

// HACK: uses react-window to render only the visible options and improve performance drastically for large lists
// source: https://codesandbox.io/p/sandbox/react-chat-simulator-yg114?file=%2Fsrc%2FChatRoom%2FRoom%2FMessages%2FMessages.css.js
const ReactWindowMenuListOptionElement = ({ index, style, setOptionElementHeight, children }) => {
  const optionElementRef = useRef({})

  useEffect(() => {
    if (optionElementRef.current) {
      setOptionElementHeight(index, optionElementRef.current.clientHeight)
    }
  }, [optionElementRef])

  return (
    <div style={style}>
      <div ref={optionElementRef}>{children}</div>
    </div>
  )
}

// HACK: uses react-window to render only the visible options and improve performance drastically for large lists
// source: https://codesandbox.io/p/sandbox/lxv7omv65l?file=%2Fstyles.css%3A20%2C1
// source: https://codesandbox.io/p/sandbox/react-chat-simulator-yg114?file=%2Fsrc%2FChatRoom%2FRoom%2FMessages%2FMessages.css.js
const ReactWindowMenuList = props => {
  if (typeof props.children?.length === 'undefined' || props.children.length <= 20)
    return <components.MenuList {...props} />

  const { children, maxHeight } = props

  const listRef = useRef()
  const optionElementHeights = useRef({})

  const setOptionElementHeight = (index, size) => {
    listRef.current.resetAfterIndex(0)
    optionElementHeights.current = { ...optionElementHeights.current, [index]: size }
  }

  const getOptionElementHeight = index => optionElementHeights.current[index] || 40

  return (
    <VariableSizeList
      height={maxHeight || 40}
      itemCount={children.length}
      itemSize={getOptionElementHeight}
      ref={listRef}
    >
      {({ index, style }) => (
        <ReactWindowMenuListOptionElement {...{ index, style, setOptionElementHeight }}>
          {children[index]}
        </ReactWindowMenuListOptionElement>
      )}
    </VariableSizeList>
  )
}

export const Select = withLocale(
  forwardRef((props, ref) => (
    <ReactSelect
      ref={ref}
      theme={customTheme}
      closeMenuOnSelect={!props.isMulti}
      {...props.selectLocale}
      {...props}
      components={{
        MultiValueContainer,
        ClearIndicator,
        DropdownIndicator,
        MultiValueRemove,
        // HACK: uses react-window to render only the visible options and improve performance drastically for large lists
        MenuList: props.useReactWindowMenuList ? ReactWindowMenuList : components.MenuList,
        ...props.components
      }}
      styles={mergeStyles(themeStyles, props.styles)}
      classNames={{ menuPortal: _state => 'react-select-portal', control: _ => 'form-control' }}
    />
  )),
  { key: 'select', attributeName: 'selectLocale' }
)

export const LegacySelect = props => {
  let value

  if (Array.isArray(props.value)) {
    value = props.value.map(val => props.options.find(opt => opt.value === val))
  } else {
    if (props.simpleValue) {
      value = props.value?.split(',')?.map(val => props.options.find(opt => opt.value === val.trim()))
    } else {
      value = props.options.find(opt => opt.value === props.value)
    }
  }

  if (!props.isMulti && Array.isArray(value)) {
    value = value[0]
  }

  const styles = {
    ...props.styles,
    control: (control_styles, state) => ({
      ...control_styles,
      ...props.style,
      ...props.styles?.control?.(control_styles, state)
    })
  }

  const dataToReturnObject = data => ({
    target: {
      value: data.value,
      id: props.id
    },
    ...data
  })

  const change = data => {
    if (typeof props.onChange != 'function') return null

    if (props.simpleValue) {
      return props.onChange(Array.isArray(data) ? data.map(({ value }) => value).join(',') : `${data?.value}`)
    }

    if (props.isMulti && data == null) data = []

    if (Array.isArray(data)) {
      return props.onChange(data.filter(d => d != null).map(dataToReturnObject))
    }

    return props.onChange(dataToReturnObject(data))
  }

  return <Select {...props} value={value} onChange={change} styles={styles} />
}
