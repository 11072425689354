import { useRef } from 'react'

import { Badge, InputGroupText, UncontrolledTooltip } from 'reactstrap'

import { faBan, faCoins, faE, faExpand, faHourglassHalf, faListCheck, faR } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ValidatedInput from 'components/modules/inputs/validated-input'
import { withLocale } from 'locale/index'

const VolumeUnitCostInput = ({
  onChange,
  calculatedFacilitySize,
  formDataCount,
  calculatedVolumeUnitCosts,
  duplicationJobsRunning = false,
  recertification = false,
  componentLocale, // NOTE: so it doesn't conflict with the passed locale from props
  ...props // HACK: a bit hacky, passing generic props, but thats how the ValidatedInput was implemented and currently there's no real reason to refactor it.
}) => {
  const onChangeWithAbsoluteValue = event => {
    onChange({ target: { id: event.target.id, value: Math.abs(parseFloat(event.target.value) || 0) } })
  }

  const pendingFormsRef = useRef()
  const noCostRef = useRef()
  const facilitySizeRef = useRef()
  const formCountRef = useRef()
  const recertificationRef = useRef()

  return (
    <ValidatedInput
      id="event.volumeUnitCost"
      type="number"
      step={0.25}
      {...props}
      onChange={onChangeWithAbsoluteValue}
      unit_de={
        <InputGroupText>
          <FontAwesomeIcon icon={faCoins} />
        </InputGroupText>
      }
      addon={
        <InputGroupText>
          {duplicationJobsRunning && (
            <>
              <Badge color="warning" className="mr-2" pill innerRef={pendingFormsRef}>
                <FontAwesomeIcon icon={faHourglassHalf} />
              </Badge>
              <UncontrolledTooltip target={pendingFormsRef} placement="top">
                {componentLocale.pendingFormDuplication}
              </UncontrolledTooltip>
            </>
          )}

          {calculatedVolumeUnitCosts === null && (
            <>
              <Badge color="warning" className="mr-2" pill innerRef={noCostRef}>
                <FontAwesomeIcon icon={faBan} />
              </Badge>
              <UncontrolledTooltip target={noCostRef} placement="top">
                {componentLocale.costCannotBeCalculated}
              </UncontrolledTooltip>
            </>
          )}

          <Badge pill color="info" innerRef={facilitySizeRef}>
            <FontAwesomeIcon icon={faExpand} className="mr-2" />
            {calculatedFacilitySize}
          </Badge>
          <UncontrolledTooltip target={facilitySizeRef} placement="top">
            {componentLocale.calculatedFacilitySize}
          </UncontrolledTooltip>

          <Badge className="ml-2" pill innerRef={formCountRef}>
            <FontAwesomeIcon icon={faListCheck} className="mr-2" />
            {formDataCount}
          </Badge>
          <UncontrolledTooltip target={formCountRef} placement="top">
            {componentLocale.formDateCount}
          </UncontrolledTooltip>

          <Badge color="success" className="ml-2" pill innerRef={recertificationRef}>
            <FontAwesomeIcon icon={recertification ? faR : faE} />
          </Badge>
          <UncontrolledTooltip target={recertificationRef} placement="top">
            {recertification ? componentLocale.recertification : componentLocale.firstCertification}
          </UncontrolledTooltip>
        </InputGroupText>
      }
    />
  )
}

export default withLocale(VolumeUnitCostInput, { key: 'event.volumeUnitCostAddons', attributeName: 'componentLocale' })
