import React, { Fragment } from 'react'

import RichTextEditor from 'react-rte'
import { Button, Collapse, Tooltip } from 'reactstrap'

import { faAngleDown, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ConfidentialBaseData extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      status: 'closed'
    }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltip_open: !this.state.tooltip_open })
  onEntering = () => this.setState({ status: 'opened' })
  onExiting = () => this.setState({ status: 'closed' })
  render = () => {
    const {
      toggle,
      toggleTooltip,
      onEntering,
      onExiting,
      state: { status, isOpen, tooltip_open },
      props: { facility, locale, editContentRight }
    } = this

    const confidential_base_data_keys = ['overview', 'accessibility_at_a_glance']

    return (
      <React.Fragment>
        <div className="collapse-container">
          <div className={`collapse-header btn clearfix ${status}`} onClick={toggle}>
            <h3 className="collapse-headline">
              <span className="collapse-headline-text">{locale.base_data.accessibility_overview.tab_name}</span>
              <span className="collapse-icon icon-append">
                <FontAwesomeIcon size="2x" icon={faAngleDown} />
              </span>
            </h3>
            {facility?.permissions?.update_confidential_base_data ? (
              <div className="list-actions">
                <Button
                  className="btn-transparent btn-icon-only"
                  color={'link'}
                  onClick={editContentRight}
                  id={`basic_information`}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Tooltip
                  delay={500}
                  placement="top"
                  isOpen={tooltip_open}
                  target={`basic_information`}
                  toggle={toggleTooltip}
                >
                  {locale.edit}
                </Tooltip>
              </div>
            ) : null}
          </div>
          <Collapse className="collapse-content" isOpen={isOpen} onEntering={onEntering} onExiting={onExiting}>
            {confidential_base_data_keys.map((confidential_base_data_key, i) => (
              <Fragment key={i}>
                <h5>{locale.base_data[`${confidential_base_data_key}_de`]}</h5>
                <RichTextEditor
                  disabled
                  value={RichTextEditor.createValueFromString(
                    facility?.base_data[confidential_base_data_key]?.de || '',
                    'html'
                  )}
                />
                <br />
                <h5>{locale.base_data[`${confidential_base_data_key}_en`]}</h5>
                <RichTextEditor
                  disabled
                  value={RichTextEditor.createValueFromString(
                    facility?.base_data[confidential_base_data_key]?.en || '',
                    'html'
                  )}
                />
                <br />
              </Fragment>
            ))}
          </Collapse>
        </div>
      </React.Fragment>
    )
  }
}

export default ConfidentialBaseData
